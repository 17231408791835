<template>
  <page-main
    title="Any additional items to add to your Resume?"
    :aside="[
    'If there\'s something that hasn\'t been covered yet, include it here.',
    'We\'ll include any skills listed here with your other skills.',
    'Important points listed here will also make it onto your resume.',
    ]"
    nextText="That's it!"
    buttonTabIndex="4"
    eventName="nav"
    @nav="nav('/Review')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Are there any other skills that you possess you would like to include?"
            :optionsList="[]"
            :selected.sync="otherSkills"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Are there any other important points about your interests and skills?"
            :optionsList="[]"
            :selected.sync="points"
            tabindex="2"
          ></r-multi-select>
        </v-col>
        <!-- <v-col cols="12" class="r-col">
          <r-textarea
            v-model="otherNotes"
            label="Anything else to note?"
            tabindex="3"
          ></r-textarea>
        </v-col> -->
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  name: "FinalInfo",
  data: () => ({}),
  components: {},
  mounted() {},
  methods: {
    save() {
      this.saveFinalInfo();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("finalInfo", ["saveFinalInfo"])
  },
  computed: {
    ...mapFields("finalInfo", ["otherSkills", "points", "otherNotes"])
  }
};
</script>
